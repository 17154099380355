import React from "react"
import { Link } from "gatsby"
import LogoShifters from "../images/shifters.png"
import { useLocation } from "@reach/router";


const Footer = () => {

  const location = useLocation();

  const isHomepage = (location.pathname == "/")

  return (
    <footer className="bg-white z-60 text-xs text-primary text-left flex flex-col sm:flex-row justify-between items-center border-t border-gray-400">
      <div className={"flex flex-col sm:flex-row sm:pl-8 text-center flex-grow " + (isHomepage ? "sm:justify-end" : "sm:justify-start")}>
        <div className="text-gray-600 mr-2">©ShiftYourJob2020</div>
        <Link to="/mentions-legales" className="hover:underline mr-2">Mentions&nbsp;légales</Link>
        <Link to="/equipe" className="hover:underline">L'équipe</Link>
      </div>
      <div className=" flex flex-col-reverse sm:flex-row-reverse items-center">
        <a href="https://theshiftproject.org/equipe" target="_blank" className="py-2">
          <img
            src={LogoShifters}
            alt="The Shifters"
            className=""
            style={{ height: "50px" }}
          />
        </a>
        <div className="border-l sm:pr-8 sm:pl-4 text-gray-600">
          Un projet porté par
            </div>
        <Link to="/contribuer" className="hover:underline sm:mr-2 sm:pr-4 sm:ml-2">Contribuer</Link>
        <Link to="/contact" className="hover:underline sm:mr-2 sm:pr-4 sm:ml-2">Contact</Link>
      </div>
    </footer>
  )
}

export default Footer